import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-aws-infrastructure"
    }}>{`Setup AWS Infrastructure`}</h1>
    <p>{`This document assumes you have followed `}<a parentName="p" {...{
        "href": "./SetupAWSAccount.md"
      }}>{`Setup AWS Account`}</a>{`.`}</p>
    <h2 {...{
      "id": "elastic-beanstalk-api"
    }}>{`Elastic Beanstalk (API)`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Login to the AWS Console.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Change to the region you want. I'm going with Sydney.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I'll be paraphrasing the `}<a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/elasticbeanstalk/latest/dg/AWSHowTo.RDS.html#rds-external-defaultvpc"
          }}>{`Launching and Connecting to an External Amazon RDS Instance in a Default VPC`}</a>{` document.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open up RDS.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a new instance.`}</p>
        <p parentName="li">{`Engine options: MySQL`}</p>
        <p parentName="li">{`Check the "Only enable options eligable for RDS Free Usage Tier" checkbox if you wish.`}</p>
        <p parentName="li">{`DB engine version: latest (5.7.21)`}</p>
        <p parentName="li">{`DB instance class: db.t2.micro`}</p>
        <p parentName="li">{`DB instance identifier: gluestack`}</p>
        <p parentName="li">{`Master username: gluestack`}</p>
        <p parentName="li">{`Master password: gluestack`}</p>
        <p parentName="li">{`Virtual Private Cloud (VPC): Default`}</p>
        <p parentName="li">{`Subnet group: default`}</p>
        <p parentName="li">{`Public accessibility: Yes`}</p>
        <p parentName="li">{`VPC security groups: Create new VPC security group`}</p>
        <p parentName="li">{`Database name: glue`}</p>
        <p parentName="li">{`Enhanced monitoring: Enabled`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select View Instance.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll down to Details, Security and network, Security Groups and select the security group.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Inbound.`}</p>
        <p parentName="li">{`The ip address that is already there is your current ip address, automatically populated. This may change in future depending on your internet setup. For example, it might change when you restart your modem.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Edit.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Add Rule.`}</p>
      </li>
    </ol>
    <p>{`Port: 3306`}</p>
    <p>{`Security Group: sg-xxxxxxxx rds-launch-wizard`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">
        <p parentName="li">{`Select Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open the Elastic Beanstalk service.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a new Application`}</p>
        <p parentName="li">{`Application Name: Glue Stack`}</p>
        <p parentName="li">{`Platform: Java`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`cd api
mvn package
`}</code></pre>
        <p parentName="li">{`Application Code: (upload jar from api/target)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Configure More Options.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Modify on the Instances configuration.`}</p>
        <p parentName="li">{`EC2 instance type: t2.micro`}</p>
        <p parentName="li">{`EC2 security group: rds-launch-wizard`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Modify on the Software configuration.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under Environment properties add the following:`}</p>
        <p parentName="li">{`RDS_HOSTNAME: (copy from the RDS tab under Connect, Endpoint)`}</p>
        <p parentName="li">{`RDS_PORT: 3306`}</p>
        <p parentName="li">{`RDS_DB_NAME: glue`}</p>
        <p parentName="li">{`RDS_USERNAME: gluestack`}</p>
        <p parentName="li">{`RDS_PASSWORD: gluestack`}</p>
        <p parentName="li">{`These are specific so that they match the properties Elastic Beanstalk would use if it configured the RDS instance for us, which didn't work for me.`}</p>
        <p parentName="li">{`SERVER_PORT: 5000`}</p>
        <p parentName="li">{`This aligns the port Spring Boot listens on with the port that Elastic Beanstalk expects (from `}<a parentName="p" {...{
            "href": "https://aws.amazon.com/blogs/devops/deploying-a-spring-boot-application-on-aws-using-aws-elastic-beanstalk/"
          }}>{`Deploying a Spring Boot Application on AWS Using AWS Elastic Beanstalk`}</a>{`).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create app.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wait a few minutes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on the URL on the top right to see your application running live.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can connect to the database instance in Sequel pro using the credentials we passed in as environemnt properties.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "cloudfront-ui"
    }}>{`Cloudfront (UI)`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to S3.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create bucket.`}</p>
        <p parentName="li">{`Bucket name: gluestack`}</p>
        <p parentName="li">{`Region: Sydney`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Next.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Next Again.`}</p>
        <p parentName="li">{`Leave the bucket as private`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Next.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create bucket.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the gluestack bucket.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Permissions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Bucket Policy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Paste the following.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`{
    "Id": "Policy1532945968718",
    "Version": "2012-10-17",
    "Statement": [
        {
        "Sid": "Stmt1532945962582",
        "Action": [
            "s3:GetObject"
        ],
        "Effect": "Allow",
        "Resource": "arn:aws:s3:::gluestack/*",
        "Principal": "*"
        }
    ]
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Build the ui.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`cd ui
yarn buld
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Upload the contents of the `}<inlineCode parentName="p">{`ui/build/`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Next.`}</p>
      </li>
    </ol>
    <p>{`Manage public permissions: Grant public read access to this bucket`}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">
        <p parentName="li">{`Select Next.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Upload.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Properties.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Static website hosting.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Use this bucket to host a website.`}</p>
        <p parentName="li">{`Index document: index.html`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy the Endpoint.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Save.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to CloudFront.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create distribution.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Get Started under the Web distribution.`}</p>
        <p parentName="li">{`Origin Domain Name: (endpoint you copied from s3)`}</p>
        <p parentName="li">{`Viewer Protocol Policy: Redirect HTTP to HTTPS`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create Distribution.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the distribution.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Origins.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create Origin.`}</p>
        <p parentName="li">{`Origin Domain Name: (Elastic Beanstalk domain)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Behaviours.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create Behaviours.`}</p>
        <p parentName="li">{`Path pattern api/`}{`*`}</p>
        <p parentName="li">{`Origin: Custom-gluestack-env`}</p>
        <p parentName="li">{`Viewer Protocol Policy: Redirect HTTP to HTTPS`}</p>
        <p parentName="li">{`Allowed HTTP Methods: GET, HEAD, OPTIONS, PUT, POST, PATCH, DELETE`}</p>
        <p parentName="li">{`Cache Based on Selected Request Headers: All`}</p>
        <p parentName="li">{`Min, Max, Default TTL: 0`}</p>
        <p parentName="li">{`Query String Forwarding and Caching: Forward all, cache based on all`}</p>
        <p parentName="li">{`Compress Objects Automatically: Yes`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select Create.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select General.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the Domain and see it all working!`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      